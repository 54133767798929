var U = require("../utils/ajax.js");
var Article={
    Cat:function(opt){
        U.ajax({
            service: 'article.articleCats',
            data: {
                "cat_id": opt.data.cat_id,
            },
            succ: function (res, all) {
                opt.succ(res,all);
            },
            fail:function (res,all) {
                opt.fail?opt.fail(res,all):'';
            },
        });
    },
    List:function(opt){
        U.ajax({
            service: 'article.articleList',
            data: {
                "cat_id": opt.data.cat_id,
                'kw':opt.data.kw,
                "page": opt.data.page,
                "limit": opt.data.limit,
            },
            succ: function (res, all) {
                opt.succ(res,all);
            },
            fail:function (res,all) {
                opt.fail?opt.fail(res,all):'';
            },
        });
    },
    Detail:function(opt){
        U.ajax({
            service: 'article.info',
            data: {
                "article_id": opt.data.article_id,
            },
            succ: function (res, all) {
                opt.succ(res,all);
            },
            fail:function (res,all) {
                opt.fail(res,all);
            },
        });
    },

    Fav:function(opt){
        U.ajax({
            service: 'goods.fav',
            data: opt.data,
            succ: function (res, all) {
                opt.succ(res,all);
            },
            fail:function (res,all) {
                opt.fail(res,all);
            },
        });
    },
    Natures:function(opt){
        U.ajax({
            service: 'goods.natures',
            data: opt.data,
            succ: function (res, all) {
                opt.succ(res,all);
            },
            fail:function (res,all) {
                opt.fail(res,all);
            },
        });
    },
    Haibao:function(opt) {
        U.ajax({
            service: 'article.haibao',
            data: {
                "article_id": opt.data.article_id,
            },
            succ: function (res, all) {
                opt.succ(res, all);
            },
            fail: function (res, all) {
                opt.fail(res, all);
            },
        });
    }
}
module.exports = {
    Article,
}