<template>
  <div class="articleList">
    <div class="container">
      <my-breadcrumb>
        <my-breadcrumb-item to="/">HOME</my-breadcrumb-item>
        <my-breadcrumb-item to="/articleList/index" :active="true"  :isDisabled="true"
          >ARTICLELIST</my-breadcrumb-item
        >
      </my-breadcrumb>

      <div class="list">
          <div class="item" @click="goInfo(item.article_id)" v-for="item in articleList" :key="item.article_id">
              <div class="img">
                  <img :src="item.cover" alt="">
              </div>
              <div class="font">
                  <div class="title">{{item.title}}</div>
                  <div class="time">{{item.createtime_text}}</div>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import { MyBreadcrumb, MyBreadcrumbItem } from "common/breadcrumb";
import { Article } from "@/model/Article.js";
export default {
  name: "ArticleList",
  data() {
    return {
        articleList:[]
    };
  },
  created() {
    this.getArticleList();
  },
  components: {
    MyBreadcrumb,
    MyBreadcrumbItem,
  },
  methods: {
    // 请求文字列表
    getArticleList() {
      Article.List({
        data: {
          cat_id: "",
          kw: "",
          page: 1,
          limit: 10,
        },
        succ: (res) => {
            this.articleList = res.article_list
        },
        fail: (res,all) => {
          this.$message('error',all.msg)
        },
      });
    },

    // 去文章详情页
    goInfo(id){
        console.log(id);
        this.$router.push({
            path:'/article/detail',
            query:{
                article_id:id
            }
        })
    }
  },
};
</script>
<style lang='scss' scoped>
.articleList {
  padding-top: 1.5rem;
  .list{
      margin-top:1.5rem;
      .item{
          transition: all 0.3s;
          &:hover{
              box-shadow: 2px 2px 5px rgba(0,0,0,.2);
          }
          border-radius: 0.3rem;
          background-color: white;
          padding-left:1rem;
          display: flex;
          margin-bottom:0.5rem;
          .img{
              width: 4rem;
            
            img{
                width: 100%;
            
            }
          }

          .font{
            padding:1rem 0;
            margin-left:1.5rem;
            .title{
                font-size: 0.875rem;
                color:var(--text-color-title);
            }
            .time{
                font-size: 0.75rem;
                color:var(--text-color-minor);
            }
          }
      }
  }
}
</style>